/**
 * Libs
 */
import { useEffect, useMemo, useState } from 'react';

type TRequirements = 'min' | 'number' | 'lower' | 'upper';
type TRequirementsColors = { [key in TRequirements]: boolean };

export const usePasswordRequirementsColor = (password = '') => {
    const [dirty, setDirty] = useState(false);

    const passwordRequirements: Record<string, boolean> = useMemo(() => ({
        min: (password?.trim().length || 0) >= 8,
        number: (password?.trim().match(/[0-9]/)?.length || 0) >= 1,
        lower: (password?.trim().match(/[a-z]/)?.length || 0) >= 1,
        upper: (password?.trim().match(/[A-Z]/)?.length || 0) >= 1
    }), [password]);

    useEffect(() => {
        if (password !== '') setDirty(true);
    }, [password]);


    return useMemo(() => Object.keys(passwordRequirements).reduce((acc, curr) => {
        acc[curr as TRequirements] = !dirty && password !== ''  ? true : passwordRequirements[curr];
        return acc;
    }, {} as TRequirementsColors), [dirty, password, passwordRequirements]);
};
