import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { media } from 'src/ui/common/mixins/media';
import styled, { css } from 'styled-components';

interface ModalOpts {
    fullScreen?: boolean;
    isCenter?: boolean;
    noBorder?: boolean;
    stickyRoot?: boolean;
}

export const StyledModalFooter = styled(FlexBox)<ModalOpts>`
    width: 100%;
    padding: 0 20px 20px;

    & button {
        white-space: nowrap;
    }

    & > * {
        flex: 1;
    }

    & > * + * {
        margin-left: 10px;
    }

    ${({ fullScreen }: ModalOpts) =>
        fullScreen &&
        css`
            position: fixed;
            bottom: 0;
            left: 0;
            height: 100px;
            background-color: ${({ theme }) => theme.colors.warmBlack};
            padding: 20px 20px 40px 20px;
            border-top: 1px solid ${({ theme }) => theme.colors.gs400};

            ${media.sm`
              position: static;
              height: auto;
              border: none;
              padding: 0 20px 20px;
            `};
        `}
`;

const heightFillMixin = `
    min-height: 100%;
    @-moz-document url-prefix() {
        height: 100vh;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 3),
        only screen and (min--moz-device-pixel-ratio: 3),
        only screen and (-o-min-device-pixel-ratio: 3/1),
        only screen and (min-device-pixel-ratio: 3) {
            height:100%;
    }
 
`;

export const StyledModalRoot = styled.div<ModalOpts>`
    position: relative;

    ${({ fullScreen }) =>
        fullScreen &&
        css`
            width: 100vw;
            height: 100vh;
            ${heightFillMixin};
        `}

    ${({ fullScreen }) =>
        fullScreen &&
        css`
            &:has(${StyledModalFooter}) {
                padding-bottom: 100px;

                ${media.sm`
                    padding-bottom: 0px;
                `};
            }
        `}

  ${({ fullScreen }: ModalOpts) =>
        !fullScreen &&
        css`
            height: auto;
            border-radius: 5px;
            margin: ${({ stickyRoot }: ModalOpts) => (stickyRoot ? 'auto 0px 0px;' : 'auto 10px 40px')};

            ${media.sm`
              width: fit-content;
              margin: auto;
            `}
        `}

  ${({ isCenter }: ModalOpts) =>
        isCenter &&
        css`
            height: auto;
            border-radius: 5px;
            margin: 0;

            ${media.sm`
              width: fit-content;
              margin: auto;
            `}
        `}

  ${({ fullScreen }) =>
        fullScreen &&
        `    
    & ${StyledModalContent} {
      height: 100%;
      ${heightFillMixin};
      border-radius: 0 !important;
      border: none !important;
      overflow-y: auto;
    }
  `};
`;

export const StyledModalContent = styled.div<ModalOpts>`
    position: relative;
    background-color: ${({ theme }) => theme.colors.warmBlack};
    background-clip: padding-box;
    outline: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    ${({ fullScreen }: ModalOpts) =>
        !fullScreen &&
        css`
            height: auto;
            border-radius: 5px;
            border: 1px solid ${({ theme }) => theme.colors.gs400};
        `}
    ${({ noBorder }: ModalOpts) =>
        noBorder &&
        css`
            border: none;
        `}
  & form ${StyledModalFooter} {
        padding: 20px 0 0;

        ${({ fullScreen }: ModalOpts) =>
            fullScreen &&
            css`
                padding: 20px 20px 40px 20px;

                ${media.sm`
                    padding: 20px 0 0;
                `};
            `}
    }
`;

export const StyledModalHeader = styled.div`
    color: white;
    background: ${({ theme }) => theme.colors.gs500};
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 18px;
    font-weight: 500;
    padding: 20px;

    ${media.md`
        font-size: 16px;
        font-weight: 500;
    `}
    & > div > svg {
        cursor: pointer;
    }
`;

export const StyledModalBody = styled.div`
    padding: 20px;
`;