import React, { ReactNode, useMemo } from 'react';
import { useScreenDetect } from 'src/hooks/useScreenDetect';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { ModalContext, useModalContext } from './modal.provider';
import {
    StyledModalBody,
    StyledModalContent,
    StyledModalFooter,
    StyledModalHeader,
    StyledModalRoot,
} from './modal.styles';

interface Props {
    children: ReactNode;
    className?: string;
    testId?: string;
}

interface RootModalProps extends Props {
    fullScreen?: boolean;
    isCenter?: boolean;
    noBorder?: boolean;
    stickyRoot?: boolean;
}

interface HeaderProps extends Props {
    onClose?: () => void;
}

export const Modal = ({ testId, children, className, fullScreen, isCenter = false, noBorder = false, stickyRoot = false }: RootModalProps) => {
    const { xs } = useScreenDetect();

    const isFullscreen = useMemo(() => {
        if (typeof fullScreen === 'undefined') {
            return xs;
        }

        return fullScreen;
    }, [fullScreen, xs]);

    const contextValue = useMemo(() => ({ fullScreen: isFullscreen }), [isFullscreen]);

    return (
        <ModalContext.Provider value={contextValue}>
            <StyledModalRoot stickyRoot={stickyRoot} isCenter={isCenter} fullScreen={isFullscreen} className={className} data-test-id={testId ?? 'modal'}>
                <StyledModalContent noBorder={noBorder} fullScreen={isFullscreen}>{children}</StyledModalContent>
            </StyledModalRoot>
        </ModalContext.Provider>
    );
};

export const ModalHeader = ({ children, className, testId, onClose }: HeaderProps) => (
    <StyledModalHeader className={className} data-test-id={testId ?? 'modal-header'}>
        <FlexBox alignItems="center" justifyContent="space-between">
            {children}
            {onClose && <SvgIcon icon="close" variant="regular" onClick={onClose} />}
        </FlexBox>
    </StyledModalHeader>
);

export const ModalBody = ({ children, className, testId }: Props) => (
    <StyledModalBody className={className} data-test-id={testId ?? 'modal-body'}>
        {children}
    </StyledModalBody>
);

export const ModalFooterActions = ({ children, className, testId }: Props) => {
    const { fullScreen } = useModalContext();

    return (
        <StyledModalFooter
            className={className}
            fullScreen={fullScreen}
            data-test-id={testId ?? 'modal-footer-actions'}
        >
            {children}
        </StyledModalFooter>
    );
};