import { ErrorI } from '@tacans/shared-marketplace-layer';
import { isValidationError } from './ajax.utils';

export type FormValidationTuple<T = {}> = [keyof T, string];

export const isFormFieldValidationError = (error: ErrorI | string, fields: string[]) =>
    typeof error === 'string' ? 
    fields.some((field) => new RegExp(field, 'gi').test(error)) : 
    isValidationError(error) && fields.some((field) => new RegExp(field, 'gi').test(error.errorMessage));

export const getFormValidationTuple = <T = any>(
    errors: string[],
    fields: (keyof T)[]
): FormValidationTuple<T>[] => {
    const validationErrorsRaw = errors.filter((e) =>
        isFormFieldValidationError(e, fields as string[])
    );

    return validationErrorsRaw.reduce((p, c) => {
        const field = fields.find((fieldName) =>
            new RegExp(fieldName as string, 'gi').test(c)
        );
        if (field) {
            return [...p, [field, c]];
        }

        return p;
    }, [] as any);
};

export const preventDefault = (e: any) => e.preventDefault();

export const formTransforms = {
    int: {
        input: (value: any) =>
            isNaN(value) ? '' : value.toString(),
        output: (e: any): string => {
            const output = parseInt(e.target.value, 10);
            return isNaN(output) ? '' : output.toString();
        }
    },
    float: {
        input: (value: any) => value,
        output: (e: any) => e.target.value
    }
};
