// MODULES
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';

// RESOURCES
import { NotificationTypeEnum } from 'src/services/notifications/types';
import { useNotifications } from 'src/services/notifications/use-notifications';
import {
    FormValidationTuple,
    getFormValidationTuple,
    isFormFieldValidationError,
} from '../utils/form.utils';
import { useIsMounted } from './useIsMounted';

interface ILocale {
    error: string;
}

export const useHandleSubmissionError = <T = {}>(fields: (keyof T)[]) => {
    const { t } = useTranslation(['common']);
    const locale: ILocale = useMemo(() => ({
        error: t('messages.error', { ns: 'common' }),
    }), [t]);

    const { show } = useNotifications();
    const isMounted = useIsMounted();

    const [validationErrors, setValidationErrors] = useState<
        FormValidationTuple<T>[]
    >([]);
    const [responseError, setResponseError] = useState<string | null>(null);

    const resetValidationErrors = useCallback(() => {
        isMounted(() => {
            setValidationErrors([]);
        });
    }, [isMounted]);

    const resetResponseError = useCallback(() => {
        isMounted(() => {
            setResponseError(null);
        });
    }, [isMounted]);

    const handleError = useCallback(
        (error: Error | string[]) => {
            if (error instanceof Error) {
                show({
                    text: error.message || locale.error,
                    type: NotificationTypeEnum.error,
                });

                return;
            }
            isMounted(() => {
                setValidationErrors(getFormValidationTuple<T>(error, fields));

                setResponseError(
                    error.find(
                        (e) => !isFormFieldValidationError(e, fields as string[])
                    ) || null
                );
            });
        },
        [fields, show, isMounted, locale]
    );

    return {
        handleError,
        validationErrors,
        responseError,
        resetValidationErrors,
        resetResponseError,
    };
};
