import { useCallback } from 'react';
import { useConnectMetamaskAction } from 'src/features/common/action-triggers/hooks/use-action-triggers';

export const useRunActionTriggersAfterLogin = () => {
    const connectMetamaskAction = useConnectMetamaskAction();

    return useCallback(() => {
        const value = sessionStorage.getItem('triggerAction');

        switch (value) {
            case 'connect-metamask': connectMetamaskAction();
        }
    }, [connectMetamaskAction]);
};
